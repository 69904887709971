import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout
      location={location}
      title={siteTitle}
      invert
      className="bg-blue-500"
    >
      <Seo
        title="Psychic Reading Services Helps You Find Real Psychics Online Through Honest Reviews."
        description="Finding real psychics can be overwhelming. We can help you! Read through our honest reviews to find the most accurate psychic reading services online today."
      />
      <Container>
        <h1>We Help You Find the Best Psychic Readings</h1>
        <p>
          Great news! The best psychic service website does exist, so give
          Google search a rest, and let us do the work for you. From Love
          Psychics to Tarot Readers and Astrologers, if you’ve been searching
          for real Psychics but feel overwhelmed by all the websites claiming to
          be the best, we got you covered. Psychic Reading Services is dedicated
          to finding, vetting, and testing all the top-rated psychic services
          providing you with the most professional, legitimate, and accurate
          psychic recommendations on the web. And, of course, services that are
          worthy of your time and money.{" "}
        </p>
      </Container>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
